import { HeaderNavItems } from '@type/buzzkuri/home'

export const ADMIN_BREADCRUMBS_NAME = {
  admin: 'TOP',
  'my-page': 'マイページ',
  'decided-events-list': '',
  'schedule-management': 'スケジュール管理',
  'undecided-events-list': '回答待ちイベント一覧',
  'password-changes': 'パスワードの変更',
  'email-changes': 'メールアドレスの変更',
}

const ADMIN = 'admin/attenders-lecturer'

export const RAILS_API_ENDPOINT = {
  EMAIL_CHANGES_LOGIN: `${ADMIN}/email_changes/edit`,
  EMAIL_CHANGES_PATCH: `${ADMIN}/email_changes`,
  PASSWORD_CHANGES_LOGIN: `${ADMIN}/password_changes/edit`,
  PASSWORD_CHANGES_PATCH: `${ADMIN}/password_changes`,
  LOGIN_POST: `${ADMIN}/session`,
  DECIDED_EVENTS_GET: `${ADMIN}/events`,
  UNDECIDED_EVENTS_GET: `${ADMIN}/unanswered_event_requests`,
  SCHEDULE_MANAGEMENT_GET: `${ADMIN}/current_lecturer_schedules?date=`,
}

export const HEADER_NAV_ITEMS: HeaderNavItems = {
  about: {
    text: 'バヅクリについて',
    href: ``,
    target: '_self',
    subNav: [
      {
        text: 'バヅクリとは',
        href: '/about',
        target: '_self',
      },
      {
        text: '人的資本とエンゲージメント',
        href: '/human_capital_engagement',
        target: '_self',
      },
    ],
  },
  service: {
    text: 'サービス',
    href: '',
    target: '_self',
    subNav: [
      {
        text: 'エンゲージメント改善',
        href: `https://hr.buzzkuri.com/engagement`,
        target: 'blank',
      },
      {
        text: '内定者フォロー',
        href: `https://tb.buzzkuri.com/naiteisha/`,
        target: 'blank',
      },
      {
        text: '社内イベント 組合レク',
        href: `https://tb.buzzkuri.com/event/`,
        target: 'blank',
      },
      {
        text: '研修',
        href: `https://hr.buzzkuri.com/training`,
        target: 'blank',
      },
    ],
  },
  programs: {
    text: 'プログラム',
    href: `/programs`,
    target: '_self',
  },
  cases: {
    text: '導入事例',
    href: `/cases`,
    target: '_self',
  },
  prices: {
    text: '料金',
    href: `/prices`,
    target: '_self',
  },
  trial: {
    text: 'お役立ち資料',
    href: `https://download.buzzkuri.com/whitepaper`,
    target: '_blank',
  },
}

export const CLOUDFRONT_ENV_KEY = {
  production: 'd1v6fx8fk7eiec',
  local: 'd1v6fx8fk7eiec',
  // unknown: 'd2gcabd6jt86of',
  // unknown2: 'd1rymue6s04l0d',
}

// export const imgSrcBase = `https://${
//   CLOUDFRONT_ENV_KEY[process.env.NEXT_PUBLIC_PM2_ENV]
// }.cloudfront.net/images/`

export const RESIZE_IMAGE_BASE_PATH = 'https://images.buzzkuri.com/resize/'

export const URLS = {
  frontend: {},
  // buzzkuri
  backend: {
    businessLogin: '/business/login',
    lecturerSchedules: '/admins/clubs/current_lecturer_schedules',
  },
  // buzzkuri_admin
  admins: {
    login: '/admins/login',
  },
  // buzzkuri以外
  external: {},
}
